<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="design-page">
    <form
      class="design-form"
      @submit.prevent="downloadFile"
    >
      <h1>Graphic Maker</h1>
      <label for="profile">
        <span>Profile Image</span>
      </label>
      <input
        @change="readProfile($event, 'profile')"
        name="profile"
        type="file"
        class="design-field"
      >
      <input
        placeholder="שם מלא"
        type="text"
        class="design-field"
        v-model="form.fullName"
      >
      <input
        placeholder="תפקיד"
        type="text"
        class="design-field"
        v-model="form.position"
      >
      <input
        placeholder="כותרת"
        type="text"
        class="design-field"
        v-model="form.title"
      >

      <input
        type="submit"
        value="Download File"
        class="design-btn"
      >
    </form>
    <div class="design-wrap">
      <div id="design">
        <p class="design-title">
          {{ form.title }}
        </p>
        <img
          v-if="profile"
          class="design-img"
          :src="profile"
        >
        <div class="design-txt">
          <p class="design-name">
            {{ form.fullName }}
          </p>
          <p class="design-position"> {{ form.position }}
          </p>
          <img
            class="design-colors"
            src="../assets/dot-colors.svg"
          >
        </div>

        <img
          class="design-logo"
          src="../assets/logo-prac.svg"
          alt=""
        >
        <div class="design-frame"></div>

      </div>
    </div>
  </div>
</template>

<script>
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';

// import logo from '../assets/logo.png';
export default {
  components: {},
  methods: {
    readProfile(evt, field) {
      var tgt = evt.target || window.event.srcElement,
        { files } = tgt;

      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = () => (this[field] = fr.result);
        // setTimeout(() => console.log(this[field]), 200);
        fr.readAsDataURL(files[0]);
      }
    },
    downloadFile() {
      var node = document.getElementById('design');
      console.log(node)
      const rect = node.getBoundingClientRect();
      htmlToImage
        .toPng(node, { backgroundColor: 'white', canvasHeight: rect.height * 2, skipAutoScale: true })
        .then(function (dataUrl) {
          download(dataUrl, 'graphics.png');
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
    }
  },
  data() {
    return {
      profile: '',
      form: {
        fullName: 'Israel Israeli',
        position: 'Head of cool things for cool people',
        title: "I'll Be There"
      }
    };
  },
  name: 'Design'
};
</script>

<style scoped>
label {
  font-size: 14px;
  line-height: 1;
  margin-top: 0px;
  display: block;
}

.design-page {
  max-width: 1400px;
  margin: 60px auto;
  position: relative;
  width: 90%;
  display: flex;
  gap: 2rem;
}

.design-field {
  display: block;
  width: 360px;
  margin-bottom: 8px;
  border: 1px solid black;
  padding: 7px;
  font-size: 16px;
}

.design-btn {
  width: 100%;
  border: none;
  background: #00DBE9;
  color: white;
  padding: 10px;
  transition: all;
}

.design-btn:hover {
  filter: brightness(90%);
}

.design-btn:active {
  filter: brightness(80%);
}

#design {
  height: 670px;
  width: 1200px;
}

.design-wrap {
  margin: 30px auto;
  transform: scale(0.5);
  transform-origin: top left;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
}

.design-title {
  color: #00DBE9;
  text-transform: uppercase;
  font-size: 41px;
  font-weight: 700;
  transform: rotate(-90deg);
  transform-origin: 361px 244px;
  position: absolute;
  top: 0px;
  left: 0;
  text-align: center;
  width: 630px;
}

.design-img {
  width: 326px;
  height: 326px;
  position: absolute;
  left: 239px;
  top: 152px;
  z-index: 2;
  object-fit: cover;
}

.design-txt {
  position: absolute;
  left: 639px;
  top: 193px;
}

.design-name {
  font-size: 44px;
  margin: 0 0 5px;
  font-weight: 700;
}

.design-position {
  font-size: 32px;
  margin: 0 0 20px;
  width: 500px;
  line-height: 1.3;
}

.design-colors {}

.design-logo {
  position: absolute;
  right: 35px;
  bottom: 30px;
  width: 328px;
}

.design-frame {
  border: 20px solid #FBDB35;
  height: 395px;
  width: 340px;
  position: absolute;
  left: 192px;
  top: 116px;
}
</style>
